import { Combobox, ComboboxOption, ComboboxOptions } from "@headlessui/react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { useOrg } from "@/contexts/OrgContext";
import { Button } from "@/components/ui/button";
import SkeletonLoader from "./SkeletonLoader";
import { Badge } from "@/components/ui/badge";
import { useGetPrimaryAssetQuery } from "@/api/api";
import { useRouter } from "next/router";
import { decode } from "he";
import { LibraryRecord } from "@/api/types/library";
import BlockEditorPreview from "../BlockEditor/BlockEditorPreview";
import { PrimaryAssetRecord, PrimaryAssetType } from "@/api/types/node";

interface Content extends LibraryRecord {
  content_id: string;
}

function classNames(...classes: (string | boolean)[]) {
  return classes.filter(Boolean).join(" ");
}

const CONTAINER_HEIGHT = "31rem";

export default function Pages() {
  const { organizationId, organizationName } = useOrg();
  const router = useRouter();

  const recentDocumentsParams = {
    text: "",
    limit: 8,
    offset: 0,
    kind: [PrimaryAssetType.PAGE],
    sort_direction: "DESC" as const,
    sort_by: "updated_at",
    tag_ids: [],
  };

  const {
    data: recentDocumentsData,
    isLoading: isLoadingRecentDocuments,
    isFetching: isFetchingRecentDocuments,
    isError: isErrorRecentDocuments,
    refetch: refetchContent,
  } = useGetPrimaryAssetQuery(recentDocumentsParams);

  useEffect(() => {
    refetchContent();
  }, []);

  if (isLoadingRecentDocuments) return <SkeletonLoader />;
  if (isErrorRecentDocuments) {
    return (
      <div>
        <p className="text-red-500">
          An error occurred while loading the data. Please try again later.
        </p>
        <SkeletonLoader />
      </div>
    );
  }

  const contents = recentDocumentsData?.results ?? [];

  return (
    <div className="w-full">
      <div className="w-full transform divide-y divide-border overflow-hidden rounded-lg ring-1 ring-border transition-all">
        <Combobox<PrimaryAssetRecord>
          onChange={(content: PrimaryAssetRecord) => {
            if (content) {
              router.push(
                `/${organizationName}/page/${content.versions?.[0]?.root_node?.id}`
              );
            }
          }}
        >
          {({ activeOption }: { activeOption: PrimaryAssetRecord | null }) => (
            <>
              <ComboboxOptions
                as="div"
                static
                hold
                className="flex transform-gpu divide-x divide-border"
              >
                <div
                  className={classNames(
                    `max-h-[${CONTAINER_HEIGHT}] min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4`,
                    !!activeOption && `sm:h-[${CONTAINER_HEIGHT}]`
                  )}
                >
                  <div className="-mx-2 text-sm text-foreground">
                    {contents.map((content) => (
                      <ComboboxOption
                        as="div"
                        key={content.id}
                        value={content}
                        className={({ focus }: { focus: boolean }) =>
                          classNames(
                            "flex cursor-default select-none items-center rounded-md px-2 py-5",
                            focus && "bg-accent-emphasis text-foreground"
                          )
                        }
                      >
                        {({ focus }: { focus: boolean }) => (
                          <>
                            <span className="ml-3 flex-auto truncate">
                              {decode(content.display_name)}
                            </span>
                            {focus && (
                              <ChevronRightIcon
                                className="ml-3 h-5 w-5 flex-none text-muted-foreground"
                                aria-hidden="true"
                              />
                            )}
                          </>
                        )}
                      </ComboboxOption>
                    ))}
                  </div>
                </div>

                {activeOption && (
                  <div className="hidden w-3/5 flex-none flex-col p-4 md:flex gap-2 h-[31.3rem]">
                    <div className="flex flex-auto flex-col text-sm text-foreground h-full overflow-y-auto p-4 pt-0 relative">
                      <div className="relative">
                        <BlockEditorPreview
                          key={activeOption.id}
                          nodeId={activeOption.versions?.[0]?.root_node?.id}
                        />
                      </div>
                    </div>
                    <div className="flex-none p-6 bg-muted/80 rounded-lg border border-border">
                      <h2 className="text-lg font-medium text-foreground truncate">
                        {decode(activeOption.display_name)}
                      </h2>
                      <p className="text-xs text-muted-foreground mt-1">
                        Last updated:{" "}
                        {new Date(activeOption.updated_at).toLocaleDateString()}
                      </p>
                      <div className="mt-2.5 flex flex-wrap gap-2">
                        {activeOption.tags && activeOption.tags.length > 0 ? (
                          activeOption.tags.map((tag) => (
                            <Badge
                              key={tag.name}
                              variant="outline"
                              className="px-1.5"
                            >
                              <span
                                className="w-2 h-2 rounded-full mr-2 inline-block"
                                style={{ backgroundColor: tag.hex_color }}
                              />
                              {tag.name}
                            </Badge>
                          ))
                        ) : (
                          <span className="text-sm text-muted-foreground"></span>
                        )}
                      </div>
                      <Button
                        className="mt-4 w-full"
                        onClick={() =>
                          router.push(
                            `/${organizationName}/page/${activeOption.versions?.[0]?.root_node?.id}`
                          )
                        }
                      >
                        Open
                      </Button>
                    </div>
                  </div>
                )}
              </ComboboxOptions>

              {contents.length === 0 && (
                <div className="p-6 text-center text-foreground">
                  <p>No content found.</p>
                </div>
              )}
            </>
          )}
        </Combobox>
      </div>
    </div>
  );
}

const containerHeightClass = `h-[${CONTAINER_HEIGHT}]`;
