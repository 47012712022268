import React from "react";
import { Check, File } from "lucide-react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useCreateNewDocument } from "@/hooks/useCreateNewPage";

const EmptyStatePages: React.FC = () => {
  const { createNewDocument } = useCreateNewDocument();

  return (
    <div className="flex flex-col">
      <ol className="space-y-10">
        <li className="opacity-50">
          <div className="flex flex-col md:flex-row items-start mb-4 gap-y-2">
            <div className="flex-shrink-0 w-7 h-7 bg-primary text-background rounded flex items-center justify-center mr-4 text-base font-mono">
              <Check className="h-4 w-4" />
            </div>
            <div className="flex flex-col space-y-1">
              <h4 className="text-xl font-mono font-semibold text-primary">
                Add sources
              </h4>
              <div className="text text-primary/75">
                Sources are the building blocks of Driver, use them as a
                knowledge base to generate docs and reference in Pages.
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:pl-10"></div>
        </li>
        <li className="flex items-start">
          <div className="flex flex-col md:flex-row items-start gap-y-2 w-full">
            <div className="flex-shrink-0 w-7 h-7 bg-primary text-background rounded flex items-center justify-center mr-4 text-base font-mono">
              2
            </div>
            <div className="flex flex-col space-y-4 w-full">
              <div className="space-y-1">
                <h4 className="text-xl font-mono font-semibold text-primary">
                  Get answers
                </h4>
                <div className="text text-primary/75">
                  Dive into tech docs about a codebase or write your own Pages.
                  <br />
                  Reference any source you add to Driver within a Page to
                  automatically generate content.
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-4 w-full">
                <Card className="p-4 rounded-lg w-full">
                  <div className="flex flex-col mb-4">
                    <File className="w-6 h-6 mb-2" />
                    <h5 className="text-base font-semibold font-mono">Page</h5>
                  </div>
                  <ul className="list-[square] pl-5 mb-4 space-y-2 text-primary/75 text-sm">
                    <li>Generate content automatically</li>
                    <li>Reference any source in Driver</li>
                    <li>Highlight and edit content with AI</li>
                  </ul>
                  <Button
                    variant="default"
                    className="w-full"
                    onClick={() =>
                      createNewDocument({
                        actionLocation: "help",
                        creationSource: "blank",
                      })
                    }
                  >
                    Start writing
                  </Button>
                </Card>
                <Card className="p-4 rounded-lg w-full bg-primary text-primary-foreground">
                  <div className="flex flex-col mt-3 mb-4">
                    <h5 className="text-base font-semibold font-mono">
                      How can I use Pages?
                    </h5>
                  </div>
                  <ul className="list-[square] pl-5 space-y-2 text-primary-foreground/75 text-sm">
                    <li>Document API endpoints</li>
                    <li>Create onboarding guides</li>
                    <li>Write technical specifications</li>
                    <li>Generate project documentation</li>
                    <li>and more...</li>
                  </ul>
                </Card>
              </div>
            </div>
          </div>
        </li>
      </ol>
    </div>
  );
};

export default EmptyStatePages;
