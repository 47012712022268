import React, { useEffect, useState, useRef } from "react";
import Head from "next/head";
import { useOrg } from "@/contexts/OrgContext";
import DefaultLayout from "@/components/LayoutDefault";
import DocumentPreview from "@/components/DocumentPreview";
import useScrollMask from "@/hooks/useScrollMask";
import ActionButton from "@/components/ActionButton";
import { FileIcon, Zap, Plus, ChevronLeft, ChevronRight } from "lucide-react";
import { InferGetServerSidePropsType } from "next";
import { getServerSideProps } from "@/pages/[organizationName]/library";
import { useGetPrimaryAssetQuery } from "@/api/api";
import SkeletonLoader from "@/components/DocumentPreview/SkeletonLoader";
import AddSourceDialog from "@/components/AddSourceDialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import Codebases from "@/components/TrendingContent/Codebases";
import Pages from "@/components/TrendingContent/Pages";
import { useCreateNewDocument } from "@/hooks/useCreateNewPage";
import { useCreateTemplate } from "@/components/Templates/useCreateTemplate";
import GettingStarted from "@/components/Guides/GettingStarted";
import EmptyStatePages from "@/components/Guides/EmptyStatePages";
import { useGetHasLibraryData } from "@/components/LibraryTable/useGetHasLibraryData";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { PrimaryAssetType } from "@/api/types/node";

function Home(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const { organizationId, organizationName, organizationDisplayName } =
    useOrg();
  const { scrollContainerRef, maskStyle, updateMaskStyle } = useScrollMask();
  const [isAddSourceDialogOpen, setIsAddSourceDialogOpen] = useState(false);
  const [selectedTrendingOption, setSelectedTrendingOption] = useState<string>(
    () => {
      if (typeof window !== "undefined") {
        return localStorage.getItem("selectedTrendingOption") || "codebases";
      }
      return "codebases";
    }
  );

  const maxRecentDocumentsToShow = 7;

  const recentDocumentsParams = {
    text: "",
    limit: maxRecentDocumentsToShow,
    offset: 0,
    kind: [PrimaryAssetType.PAGE],
    sort_direction: "DESC" as const,
    sort_by: "updated_at",
    tag_ids: [],
  };

  const { createNewDocument } = useCreateNewDocument();
  const { createTemplate } = useCreateTemplate();

  const { data, isLoading, isFetching, isError } = useGetPrimaryAssetQuery(
    recentDocumentsParams
  );

  const { hasRecords, isLoading: hasRecordsLoading } = useGetHasLibraryData();

  const showLoading = isLoading || isFetching || hasRecordsLoading;
  const libraryError = isError;

  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  const scroll = (direction: "left" | "right") => {
    if (scrollContainerRef.current) {
      const scrollAmount = 300; // Adjust this value as needed
      scrollContainerRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } =
        scrollContainerRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth - 1); // -1 to account for potential rounding errors
    }
    updateMaskStyle();
  };

  useEffect(() => {
    handleScroll(); // Initial check
  }, [data, showLoading]);

  useEffect(() => {
    localStorage.setItem("selectedTrendingOption", selectedTrendingOption);
  }, [selectedTrendingOption]);

  if (!organizationId) {
    return null;
  }

  return (
    <>
      <Head>
        <title>{organizationDisplayName || "Driver"} - Home</title>
      </Head>
      <DefaultLayout navbarTitle="Home">
        <div className="">
          {organizationId && organizationName && (
            <div className="space-y-8 w-full">
              {libraryError && (
                <Alert variant="destructive">
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>
                    There was an error loading the library.
                  </AlertDescription>
                </Alert>
              )}
              {!showLoading && !hasRecords ? (
                <div className="flex flex-col items-start justify-center min-h-[90vh]">
                  <div className="flex flex-col space-y-2 mb-10">
                    <h1 className="text-2xl font-semibold">Get started</h1>
                    <h3 className="text-base font-medium">
                      Add assets. Ask questions. Decode faster.
                    </h3>
                  </div>
                  <GettingStarted />
                </div>
              ) : !showLoading && (!data || data.results.length === 0) ? (
                <div className="flex flex-col items-start justify-center min-h-[90vh]">
                  <div className="flex flex-col space-y-2 mb-10">
                    <h1 className="text-2xl font-semibold">Get started</h1>
                    <h3 className="text-base font-medium">
                      Add assets. Ask questions. Decode faster.
                    </h3>
                  </div>
                  <EmptyStatePages />
                </div>
              ) : (
                <>
                  <div className="flex flex-wrap gap-2 md:gap-4 w-full">
                    <ActionButton
                      icon={FileIcon}
                      title="Create new page"
                      description="Cutting edge documentation tools"
                      onClick={() =>
                        createNewDocument({
                          actionLocation: "home",
                          creationSource: "blank",
                        })
                      }
                    />
                    <ActionButton
                      icon={Zap}
                      title="Create new template"
                      description="Supercharge your creation flow"
                      onClick={() => createTemplate()}
                    />
                    <ActionButton
                      icon={Plus}
                      title="Add new source"
                      description="Connect codebases, PDFs, and more"
                      onClick={() => setIsAddSourceDialogOpen(true)}
                    />
                    <AddSourceDialog
                      isOpen={isAddSourceDialogOpen}
                      onClose={() => setIsAddSourceDialogOpen(false)}
                      organizationName={organizationName}
                    />
                  </div>

                  <div className="space-y-4">
                    <div className="text-lg font-medium text-foreground">
                      Recent pages
                    </div>
                    <div className="relative group/recent-docs">
                      {showLeftButton && (
                        <button
                          onClick={() => scroll("left")}
                          className="absolute left-0 top-1/2 -translate-y-3/4 z-10 bg-background p-2 rounded-full border border-border text-muted-foreground hover:text-foreground active:bg-muted shadow-md opacity-0 group-hover/recent-docs:opacity-100 transition-opacity"
                        >
                          <ChevronLeft className="h-5 w-5" />
                        </button>
                      )}
                      <div
                        ref={scrollContainerRef}
                        className="flex space-x-4 overflow-x-scroll no-scrollbar"
                        style={maskStyle}
                        onScroll={handleScroll}
                      >
                        {isLoading
                          ? [...Array(4)].map((_, index) => (
                              <SkeletonLoader key={index} />
                            ))
                          : data?.results
                              .slice(0, maxRecentDocumentsToShow)
                              .map((doc) => (
                                <DocumentPreview
                                  key={doc.id}
                                  nodeId={
                                    doc.versions?.[0]?.root_node?.id || ""
                                  }
                                  title={doc.display_name}
                                  lastEdited={doc.updated_at}
                                  link={`/${organizationName}/page/${doc.versions?.[0]?.root_node?.id}`}
                                />
                              ))}
                      </div>
                      {showRightButton && (
                        <button
                          onClick={() => scroll("right")}
                          className="absolute right-0 top-1/2 -translate-y-3/4 z-10 bg-background p-2 rounded-full border border-border text-muted-foreground hover:text-foreground active:bg-muted shadow-md opacity-0 group-hover/recent-docs:opacity-100 transition-opacity"
                        >
                          <ChevronRight className="h-5 w-5" />
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="space-y-4">
                    <div className="flex items-center space-x-2">
                      <div className="text-lg font-medium text-foreground">
                        Trending
                      </div>
                      <Select
                        value={selectedTrendingOption}
                        onValueChange={setSelectedTrendingOption}
                      >
                        <SelectTrigger className="border-none shadow-none ps-2 pe-1 py-1 hover:bg-muted h-auto w-auto focus:outline-none focus:ring-0 gap-1.5 text-muted-foreground">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="codebases">Codebases</SelectItem>
                          <SelectItem value="pages">Pages</SelectItem>
                        </SelectContent>
                      </Select>
                    </div>
                    {selectedTrendingOption === "codebases" && <Codebases />}
                    {selectedTrendingOption === "pages" && <Pages />}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </DefaultLayout>
    </>
  );
}

export default Home;
