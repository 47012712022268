import React from "react";
import { Button } from "@/components/ui/button";
import { LucideIcon } from "lucide-react";

interface ActionButtonProps {
  icon: LucideIcon;
  title: string;
  description: string;
  onClick?: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  icon: Icon,
  title,
  description,
  onClick,
}) => {
  return (
    <Button
      variant="outline"
      className="flex items-center flex-1 justify-start px-4 py-8 space-x-4 border rounded-md hover:bg-card hover:shadow-lg transition-all duration-300"
      onClick={onClick}
    >
      <div className="flex items-center justify-center w-10 h-10 bg-muted rounded-md">
        <Icon className="w-5 h-5 text-muted-foreground" />
      </div>
      <div className="flex flex-col text-left">
        <span className="text-sm text-foreground">{title}</span>
        <span className="text-sm text-muted-foreground font-normal">
          {description}
        </span>
      </div>
    </Button>
  );
};

export default ActionButton;
