import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { Sparkles } from "lucide-react";

const InstructionPreview = (props: NodeViewProps) => {
  const { node } = props;

  return (
    <NodeViewWrapper className="relative my-4">
      <div
        className="relative rounded-lg border border-border"
        data-testid="instruction-preview"
      >
        <div
          className="rounded-lg px-4 py-6 group/main"
          data-testid="instruction-saved"
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center me-1 gap-3 w-full">
              <Sparkles className="w-4 h-4 text-indigo-500 dark:text-indigo-400 fill-indigo-500 dark:fill-indigo-400 flex-shrink-0" />
              <div className="w-full text-indigo-600 dark:text-indigo-300 bg-transparent border-none outline-none focus:ring-0 p-0 overflow-hidden">
                {node.attrs.prompt}
              </div>
            </div>
          </div>
        </div>
      </div>
    </NodeViewWrapper>
  );
};

export default InstructionPreview;
