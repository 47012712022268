import { useCreateTemplateMutation } from "@/api/api";
import { useToast } from "@/components/ui/use-toast";
import { useOrg } from "@/contexts/OrgContext";
import { useRouter } from "next/router";

export const useCreateTemplate = () => {
  const [createTemplate] = useCreateTemplateMutation();
  const { toast } = useToast();
  const { organizationName } = useOrg();
  const router = useRouter();

  const handleCreateTemplate = async () => {
    try {
      const res = await createTemplate();

      if ('error' in res) {
        throw new Error("Failed to create template");
      }

      router.push(
        `/${organizationName}/template/${res.data.node_id}`
      );
      toast({ title: "Template created" });

      return res.data;
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to create template",
        variant: "destructive",
      });
      throw error;
    }
  };

  return { createTemplate: handleCreateTemplate };
};