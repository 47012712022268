import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useRouter } from "next/router";
import { ChevronLeft, FolderCode, Upload } from "lucide-react";
import SourceButton from "./SourceButton";
import PdfDropzone from "@/components/UploadService/PdfDropzone";
import { Button } from "@/components/ui/button";
import { AnimatePresence, motion } from "framer-motion";

interface AddSourceDialogProps {
  isOpen: boolean;
  onClose: () => void;
  organizationName: string;
}

const AddSourceDialog: React.FC<AddSourceDialogProps> = ({
  isOpen,
  onClose,
  organizationName,
}) => {
  const router = useRouter();
  const [showDropzone, setShowDropzone] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setShowDropzone(false);
    }
  }, [isOpen]);

  const handleAddCodebase = () => {
    router.push(`/${organizationName}/new`);
    onClose();
  };

  const handleUploadPDF = () => {
    setShowDropzone(true);
  };

  const handleBack = () => {
    setShowDropzone(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-fit overflow-hidden p-0">
        <div className="relative">
          <AnimatePresence initial={false} mode="wait">
            {!showDropzone ? (
              <motion.div
                key="source-buttons"
                initial={{ opacity: 0, x: "-100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "-100%" }}
                transition={{ duration: 0.25, ease: "easeInOut" }}
                className="flex gap-4 p-6"
              >
                <SourceButton
                  onClick={handleAddCodebase}
                  icon={FolderCode}
                  title="Connect a codebase"
                  subtitle="GitHub, zip"
                />
                <SourceButton
                  onClick={handleUploadPDF}
                  icon={Upload}
                  title="Upload files"
                  subtitle="pdf"
                />
              </motion.div>
            ) : (
              <motion.div
                key="dropzone"
                initial={{ opacity: 0, x: "100%" }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: "100%" }}
                transition={{ duration: 0.2, ease: "easeInOut" }}
                className="p-6"
              >
                <div className="space-y-2">
                  <div className="flex items-center gap-1">
                    <Button
                      onClick={handleBack}
                      variant="ghost"
                      className="px-2"
                    >
                      <ChevronLeft className="h-4 w-4" />
                    </Button>
                    <div>Upload files</div>
                  </div>
                  <PdfDropzone />
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddSourceDialog;
