import React, { useMemo } from "react";
import BlockEditorPreview from "../BlockEditor/BlockEditorPreview";

const hashStringToNumber = (str: string) => {
  if (!str) return 0;
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const generateColorFromHash = (hash: number) => {
  if (!hash) return "rgba(0,0,0,0.05)";
  const r = (hash & 0xff0000) >> 16;
  const g = (hash & 0x00ff00) >> 8;
  const b = hash & 0x0000ff;
  const a = 0.05; // Fixed transparency
  return `rgba(${r},${g},${b},${a})`;
};

interface PreviewCardProps {
  height?: number;
  nodeId?: string;
  content?: string;
}

const PreviewCard: React.FC<PreviewCardProps> = ({
  height,
  nodeId,
  content,
}) => {
  const colorHash = useMemo(() => hashStringToNumber(nodeId || ""), [nodeId]);

  const randomColor = useMemo(
    () => generateColorFromHash(colorHash),
    [colorHash]
  );

  return (
    <div
      className="w-full px-6 pt-6 pb-0 rounded-lg border border-border/75 dark:border-border/30 overflow-hidden"
      style={{ backgroundColor: randomColor }}
    >
      <div
        className=" h-40 ring-1 ring-border rounded-t-md bg-card dark:bg-background shadow-md group-hover:shadow-2xl translate-y-1 group-hover:-translate-y-0 transition-all duration-300 overflow-hidden"
        style={{ height: height ? `${height}px` : "10rem" }}
      >
        <div className="relative h-full">
          <div className="scale-[0.5] origin-top-left h-[200%] w-[200%] overflow-hidden">
            <div className="pt-4 px-12">
              <BlockEditorPreview nodeId={nodeId} content={content} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewCard;
