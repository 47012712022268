import React from "react";

interface DocumentPreviewSkeletonProps {
  className?: string;
}

const DocumentPreviewSkeleton: React.FC<DocumentPreviewSkeletonProps> = ({
  className,
}) => {
  return (
    <div className={`flex flex-col flex-1 max-w-64 ${className}`}>
      <div className="w-full px-6 pt-6 pb-0 bg-muted rounded-lg border border-border overflow-clip">
        <div className="h-40 rounded-t-md bg-card dark:bg-background shadow-md flex flex-col space-y-1 p-4">
          {[...Array(14)].map((_, index) => (
            <div
              key={index}
              className="h-1 bg-muted-foreground/5 rounded animate-pulse"
              style={{ width: `${Math.floor(Math.random() * 71) + 30}%` }}
            ></div>
          ))}
        </div>
      </div>
      <div className="mt-4 space-y-1.5">
        <div className="h-4 bg-muted-foreground/10 rounded animate-pulse w-3/4"></div>
        <div className="flex items-center gap-1">
          <div className="w-3 h-3 bg-muted-foreground/10 rounded-full animate-pulse"></div>
          <div className="h-3 bg-muted-foreground/10 rounded animate-pulse w-1/4"></div>
        </div>
      </div>
    </div>
  );
};

export default DocumentPreviewSkeleton;
