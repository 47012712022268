import React, { useMemo } from "react";
import { formatDistanceToNow, parseISO } from "date-fns";
import { UserRoundPen } from "lucide-react";
import Link from "next/link";
import { decode } from "he";
import PreviewCard from "./PreviewCard";

interface DocumentPreviewProps {
  nodeId: string;
  title: string;
  lastEdited: string;
  link: string;
  className?: string;
}

const DocumentPreview: React.FC<DocumentPreviewProps> = ({
  nodeId,
  title,
  lastEdited,
  link,
  className,
}) => {
  const relativeTime = useMemo(() => {
    const date = parseISO(lastEdited);
    const now = new Date();
    const fourWeeksInMs = 4 * 7 * 24 * 60 * 60 * 1000; // 4 weeks in milliseconds
    if (now.getTime() - date.getTime() > fourWeeksInMs) {
      const options: Intl.DateTimeFormatOptions = {
        month: "short",
        day: "numeric",
      };
      if (date.getFullYear() !== now.getFullYear()) {
        options.year = "numeric";
      }
      return date.toLocaleDateString("en-US", options);
    }

    const distance = formatDistanceToNow(date, { addSuffix: true });
    return distance
      .replace(
        /(\d+)\s+(day|hour|week|month|year|minute)s?/g,
        (_, num, unit) => `${num}${unit[0]}`
      )
      .replace(/about /, "")
      .replace(/less than a minute ago/, "Just now")
      .replace(/1m ago/, "Just now")
      .replace(/less than /, "<")
      .replace(/over /, ">")
      .replace(/almost /, "~");
  }, [lastEdited]);

  return (
    <Link href={link}>
      <div
        className={`flex flex-col group flex-1 w-64 hover:cursor-pointer ${className}`}
      >
        <PreviewCard nodeId={nodeId} height={160} />
        <div className="mt-4 space-y-1">
          <div className="text-sm font-medium text-foreground line-clamp-1 overflow-hidden">
            {decode(title)}
          </div>
          <div className="flex items-center text-muted-foreground gap-1">
            <UserRoundPen className="w-3 h-3" />
            <div className="text-xs">{relativeTime}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default DocumentPreview;
